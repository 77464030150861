import React, { useState } from "react";
import Ruhiyye from "../assets/image/teams/Ruhiyye.jpeg";
import Nurane from "../assets/image/teams/Nuranee.jpg";
import Pervin from "../assets/image/teams/Pervin.jpg";
import Ramile from "../assets/image/teams/Ramile.jpg";
import Xatire from "../assets/image/teams/Xatire.jpg";
import Zerife from "../assets/image/teams/Zerife.jpg";
import TeamModal from "./TeamModal"; // Assuming this component is defined elsewhere

const Teams = () => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);

  const teamMembers = [
    {
      name: "Ruhiyyə Əliyeva Nadir",
      role: "Təsisçi",
      imageUrl: Ruhiyye,
      details:
        "Ruhiyyə Əliyeva Nadir qızı 1 may 1983-cü il Qubada doğulub. Moda ilə bağlı ilk təhsili “Vakko ESMOD İstanbul Uluslararası Moda Akademisi”-də “Styling(stilistika),Geyimlərin Konstruksiyası və Dizaynı”(Turkiyə,İstanbul) ixtisasları olub. Daha sonra “Aron Factor School”-da “Konstruktor ”(Rusiya, Moskva), “Viki sews”-də “İç geyimlərin konstruksiyası və tikiş texnologiyası”(Rusiya,Moskva), “Ewst Fashion Lab”-da “təkmilləşmiş mulaj-modelləmə”(Gürcüstan, Tbilisi), “Metin Gürsoy”-un “Lüks Marka Yönetimi” proqramlarını bitirib. 13 oktyabr 2019-cu il Qubada “Azerbaijan Fashion School” və 26 iyun 2021-ci il Bakıda “Azerbaijan Fashion Lab” tədris mərkəzlərini təsis edib. O həmçinin “AFL Geyim Üzrə Konstruksiya Və Modelləmə Üsulları” metodikasının və eyniadlı kitabın müəllifidir. Eyni zamanda ”RUH” brendinin təsisçisi və art direktorudur.",
    },
    {
      name: "Pərvin Əyyubova Elman",
      role: "Təlimçi",
      imageUrl: Pervin,
      details:
        "Pərvin Əyyubova Elman qızı 21 aprel 1995-ci ildə Qubada doğulub. Moda ilə bağlı ilk təhsili ” İMA - İstanbul Moda Akademisi”-də “ Rəng interpretasiyası”(Türkiyə,İstanbul) proqramı olub. Daha sonra “Grasser”-da “Texniki eskiz”(Rusiya ,Sankt-Peterburq), “Aron Factor School”-da “Konstruktor ”(Rusiya, Moskva),“Fine craft”(Rusiya,Rostov-Na-Donu)-da “Konstruksiya sənədləşməsi”, “Viki sews”-də “İç geyimlərinin Konstruksiyası və Tikiş texnologiyası”(Rusiya,Moskva) proqramlarını bitirib. 2019-cu ildə “Azerbaijan Fashion School”-da- “Dizayn ixtisası üzrə qabiliyyət imtahanına hazırlıq” və “Moda Eskizi” proqramlarınn tədrisi üzrə çalışıb. Hal hazırda isə “Azerbaijan Fashion Lab AFL AFS MMC”-də “Konstruktor” proqramı üzrə “Təlimçi” vəzifəsində çalışır. O həmçinin “AFL Geyim Üzrə Konstruksiya Və Modelləmə Üsulları” kitabının müəlliflərindən biridir.",
    },
    {
      name: "Xatirə Məmmədzadə Natiq",
      role: "Koordinator-Administrator",
      imageUrl: Xatire,
      details:
        "Xatirə Məmmədzadə Natiq qızı 21 aprel 2003-cü ildə Bakıda doğulub. Moda ilə bağlı təhsili “Azerbaijan Fashion Lab”-da “Moda dizaynı və Texnologiyası” proqramı olub. Hal hazırda “Azerbaijan Fashion Lab AFL AFS MMC”-də “Koordinator-Administrator” vəzifəsində çalışır.",
    },
    {
      name: "Nuranə İbrahimova Vitaliy",
      role: "Təlimçi",
      imageUrl: Nurane,
      details:
        "Nuranə İbrahimova Vitaliy qızı 3 iyun 1985-ci il Bakıda doğulub. Moda ilə bağlı təhsili “Azerbaijan Fashion Lab”-da “Moda Dizaynı və texnologiyası” proqramı olub. (2017-2020)-ci illər “Ev Tekstili” üzrə “Tikiş texnoloqu” vəzifəsində çalışıb. Hal hazırda isə “Azerbaijan Fashion Lab AFL AFS MMC”-də “Konstruktor” proqramı üzrə “Təlimçi” vəzifəsində çalışır.",
    },
    {
      name: "Zərifə Ramazanova Eldar",
      role: "Təlimçi",
      imageUrl: Zerife,
      details:
        "Zərifə Ramazova Eldar qızı 17 may 2000-ci il Mingəçevirdə doğulmuşdur. Moda ilə bağlı ilk təhsili “İMA İstanbul Moda Akademisi”-də “Moda Dizaynına Giriş” ixtisası olub. Daha sonra “Bocconi”-də “Moda və Lüks brendlərin idarə edilməsi”, ”Kaliforniya İncəsənət Universiteti”-də “Qrafik Dizaynın Təməli”, ”DesTech”-də “Qrafik və Uİ/UX dizayn” , “Azerbaijan Fashion Lab”-da “Konstruktor tikiş texnoloqu” proqramlarını bitirib. “Mosaic MMC”-də “Twinset” geyim brendinin “Qrafik Dizayneri” vəzifəsində çalışıb. Hal hazırda isə “Azerbaijan Fashion Lab AFL AFS MMC”-də “ Moda Dizaynı və texnologiyası” üzrə “Təlimçi” vəzifəsində çalışır.",
    },
    {
      name: "Ramilə İsmayılova Zakir",
      role: "Təlimçi",
      imageUrl: Ramile,
      details:
        "Ramilə İsmayılova Zakir qızı 18 yanvar 1999-cu il Bakıda doğulub. (2021-2023)-ci illər “Sophie Couture”-da “Tikiş texnoloqu” vəzifəsində fəaliyyət göstərib. Hal hazırda isə “Azerbaijan Fashion Lab AFL AFS MMC”-də “Tikiş Texnoloqu” proqramı üzrə “Təlimçi” vəzifəsində çalışır.",
    },
  ];

  const openModalWithPerson = (person) => {
    setSelectedPerson(person);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
    setSelectedPerson(null);
  };

  return (
    <div className="container my-12 mx-auto px-4 md:px-12">
      <div class="text-center mb-12">
            <h1 class="text-4xl md:text-6xl text-gray-700 font-semibold">
              Komandamız
            </h1>
          </div>
      <div className="flex flex-wrap -mx-1 lg:-mx-4">
        {teamMembers.map((person) => (
          <div
            key={person.name}
            className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3"
          >
            <article className="overflow-hidden rounded-lg shadow-lg">
              <img
                alt={person.name}
                className="block h-auto w-full"
                src={person.imageUrl}
              />

              <header className="flex items-center leading-tight p-2 md:p-4">
                <h1 className="text-lg">
                  <a
                    className="no-underline hover:underline text-black"
                    onClick={() => openModalWithPerson(person)}
                  >
                    {person.name}
                  </a>
                </h1>
              </header>
              <p className="text-grey-darker text-sm ml-4 font-semibold">
                {person.role}
              </p>
              <button
                  className="my-4 px-8 ml-4 py-2 text-black rounded-lg border border-black"
                  onClick={() => openModalWithPerson(person)}
                >
                  Daha çox
                </button>
            </article>
          </div>
        ))}
      </div>

      {openModal && selectedPerson && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-lg p-6">
            <h3 className="text-xl font-semibold mb-4">
              {selectedPerson.name}
            </h3>
            <p className="text-gray-700">{selectedPerson.details}</p>
            <button
              className="mt-6 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 float-right"
              onClick={closeModal}
            >
              Bağla
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Teams;
