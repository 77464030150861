import React from "react";

const Blog = () => {
  return (
    <div className="relative mt-40">
      <div className="max-w-3xl mb-10 rounded overflow-hidden flex flex-col mx-auto text-center">
        <a
          href="#"
          className="max-w-3xl mx-auto text-xl sm:text-4xl font-semibold inline-block transition duration-500 ease-in-out inline-block mb-2 italic"
        >
          Hər biri təsisçi Ruhiyyə Əliyeva tərəfindən yazılmışdır.
        </a>
        <a href="#">
          <img
            className="w-full my-4"
            src="https://images.pexels.com/photos/5120892/pexels-photo-5120892.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=625.0&sharp=10&w=1500"
            alt="Sunset in the mountains"
          />
        </a>
        <p className="text-gray-700 text-base leading-8 max-w-2xl mx-auto">
          - İmic alınıb satılmaz. Olmayanın üstünə qurulmaz. Təkrar yaradılmaz.
          Çünki işin özündə biz varıq. Marka imici ilə özümüz, olduğumuz
          arasında nə qədər uzaqlaşma olarsa o qədər kontroldan çıxar.
        </p>{" "}
        &nbsp;
        <p>
          İmic yaratmaq üçün pencək geyməliyəm. Unut. Masamda qələmlər, kitablar
          olsun gərək. Unut. Özümü ciddi göstərməliyəm. Onu da unut. Marka
          əslində vəddir. Markalaşma müddətində vədlərimizdə nə qədər tutarlı
          olsaq, imic o qədər sağlam formalaşar. Rəqabətdən qaçma. Təbii ki,
          qarşımıza hər zaman rəqibin etik olanı çıxmayacaq Rəqabət olmalı,
          mütləq... Strateji mövqedə özümüzə bir sahə seçirik və orada
          yüksəlməyə cəmlənirik. Hər yerdə olma. Hər şey etmə. Bizi izdihamdan
          fərqləndirən vizual kimliyimiz özümüzük. Sözlü kimliyimiz, üslubumuz,
          seçdiyimiz kəlmələrdi.
        </p>
        <div className="py-5 text-sm font-regular text-gray-900 flex items-center justify-center">
          <span className="mr-3 flex flex-row items-center">
            <svg
              className="text-indigo-600"
              fill="currentColor"
              height="13px"
              width="13px"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              style={{ enableBackground: "new 0 0 512 512" }}
              xmlSpace="preserve"
            >
              <g>
                <g>
                  <path
                    d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z M277.333,256
          c0,11.797-9.536,21.333-21.333,21.333h-85.333c-11.797,0-21.333-9.536-21.333-21.333s9.536-21.333,21.333-21.333h64v-128
          c0-11.797,9.536-21.333,21.333-21.333s21.333,9.536,21.333,21.333V256z"
                  />
                </g>
              </g>
            </svg>
            <span className="ml-1">August, 2024</span>
          </span>
          <a
            href="#"
            className="flex flex-row items-center hover:text-indigo-600  mr-3"
          >
            <svg
              className="text-indigo-600"
              fill="currentColor"
              height="16px"
              aria-hidden="true"
              role="img"
              focusable="false"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="currentColor"
                d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
              ></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
            <span className="ml-1">Ruhiyyə Əliyeva</span>
          </a>
          <a
            href="#"
            className="flex flex-row items-center hover:text-indigo-600"
          >
            <svg
              className="text-indigo-600"
              fill="currentColor"
              height="16px"
              aria-hidden="true"
              role="img"
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 18 18"
            >
              <path d="M15.4496399,8.42490555 L8.66109799,1.63636364 L1.63636364,1.63636364 L1.63636364,8.66081885 L8.42522727,15.44178 C8.57869221,15.5954158 8.78693789,15.6817418 9.00409091,15.6817418 C9.22124393,15.6817418 9.42948961,15.5954158 9.58327627,15.4414581 L15.4486339,9.57610048 C15.7651495,9.25692435 15.7649133,8.74206554 15.4496399,8.42490555 Z M16.6084423,10.7304545 L10.7406818,16.59822 C10.280287,17.0591273 9.65554997,17.3181054 9.00409091,17.3181054 C8.35263185,17.3181054 7.72789481,17.0591273 7.26815877,16.5988788 L0.239976954,9.57887876 C0.0863319284,9.4254126 0,9.21716044 0,9 L0,0.818181818 C0,0.366312477 0.366312477,0 0.818181818,0 L9,0 C9.21699531,0 9.42510306,0.0862010512 9.57854191,0.239639906 L16.6084423,7.26954545 C17.5601275,8.22691012 17.5601275,9.77308988 16.6084423,10.7304545 Z M5,6 C4.44771525,6 4,5.55228475 4,5 C4,4.44771525 4.44771525,4 5,4 C5.55228475,4 6,4.44771525 6,5 C6,5.55228475 5.55228475,6 5,6 Z"></path>
            </svg>
            <span className="ml-1">Təsisçi</span>
          </a>
        </div>
        <hr />
      </div>

      <div className="max-w-3xl mx-auto">
        <div className="mt-3 bg-white rounded-b lg:rounded-b-none lg:rounded-r flex flex-col justify-between leading-normal">
          <div className="">
            <p className="text-base leading-8 my-5">
              Moda tutqusu olan insanlarla tanış olmağa varsan? "MODA"nı sevirəm
              demək yetməz, uğurumuz etdiklərimiz qədərdi. Bilik əsaslı
              tədrisdən bacarıq əsaslı tədrisə keçmək dönəmi çoxdan gəlib.
              Bildiklərini tətbiq et!
            </p>
            <h3 className="text-2xl font-bold my-5">
              Moda dizaynı təhsili dünyada daha çox 2 cür tədris olunur.
            </h3>
            <p className="text-base leading-8 my-5">
              <h6>1.Sənət yönlü ( artistic)</h6>
              <h6>2.Sənaye yönlü (Industrial)</h6>
              Moda dizaynı deyildiyi zaman bu işi çox bəsit moda eskizi ilə
              sərhədləmiyək. Baxın, dünyanın ən uğurlu dizaynerləri kimdir desək
              Karl Lagerfeld ön sıralardadı. Niyə? Çünki ən çox satış göstərən
              geyimlər yaratdı. Demək ki, moda dizaynından bəhs edərkən, mən
              gecə bunu xəyal etdim, nədənsə ilham aldım-dan əlavə gerçək bir
              sektor məntiqilə, toplum tələblərilə, trendlərlə, etno
              psixologiyanı diqqətə alaraq doğru geyim kompozisiyasını yaradacaq
              dizaynerlər yetişməlidir. Ən yaxşı geyimi dizayn edə bilərik amma
              onu doğru düzgün texnikalarla asılqana asmadığımız müddətdə uğurlu
              sayılmarıq. Moda dizayneri, xəyal gücünü insan üzərinə geyilə
              biləcək,satıla biləcək hala gətirən bütün təlimləri alan insandır.
            </p>
            <blockquote className="text-md italic leading-8 my-5 p-5 text-black font-semibold">
              <h2>- Kolleksiya yaradarkən diqqət olunmalı dörd qayda.</h2>
              <h6>
                1.Az "assortimentdən" qorxmayın. Adətən geniş assortiment üçün
                "startup"ların finansal və fiziki gücü çatmır. Bunu xeyrinizə
                işlədə bilərsiz. Müxtəlif modellər test edib hansı kateqoriyaya
                daha çox investisiya edəcəksizsə anlayırsız. Tam kolleksiya üçün
                gücünüz yoxdursa başqa strategiya seçərək pulunuzu mini-kapsul,
                drop və ya mono məhsula yönəldin.
              </h6>
              <h6>
                2.Işə giriş üçün parametrlər təyin edin. Kimin üçün necə məhsul
                yaradırsız? Auditoriyanızı və statistikanızı təyin edin ki,
                keyfiyyətiniz və qiymət kateqoriyanız aydın olsun. Bunlar təyin
                olunmadan çətin satıla bilən məhsul çıxarmaq ehtimalı yüksəkdir.
                Ən çox qalıqlar buna görə yaranır və brend gəlirini itirir.
                Unutmuruq, bazar durmadan inkişaf edir və trendlər dəyişir.
              </h6>
              <h6>
                3.Əlavə qiymət üzərində işləyin. Əlavə dəyər kolleksiyada bütün
                məhsullara eyni olmaq məcburiyyətində deyil. Ənənəvi olaraq üst
                geyimlərə dəyər digərlərinə nisbətən daha çox olur. Startdan
                əvvəl kolleksiyanın gəlirini hesablamaq lazımdır.
              </h6>
              <h6>
                4.Istehsal sürətindən faydalanın. Böyük markalar üçün ideyadan
                qarderoba qədər yol bir ildir. Kiçik brendlər üçün isə bu müddət
                2,3 ay edir.
              </h6>
            </blockquote>
            <p className="text-base leading-8 my-5">
              - DROP. Məhdud tirajda mini kolleksiyanın çıxarılmasıdır. Bununla
              markalar ıstehlakçılar arasında ajiotaj yaradır. Yeni markalaşma
              dönəmində ilk mini kolleksiyanın yaradılmasında DROP istifadə
              edilə bilər. POP-UP STORE. Qeyri adi yerlərdə qısa müddətli satış
              yeridir. Adətən pop-up limitli kolleksiyaların çıxma ərəfəsində
              açılır. Markanın xüsusi təklifi zamanı və ya xüsusi bir tarixi
              gündə açılır. Əsas xüsusiyyəti odur ki, qısa müddətlidir. (Bir
              neçə saatdan bir neçə aya qədər) CAPSULE COLLECTION. Kapsul
              kolleksiyası əsasən 5 dən 12 yə qədər baza geyimləri özündə təşkil
              edir. Kolleksiyadakı bütün geyimlər öz aralarında kombin oluna
              bilir. Bu kolleksiya geyimlərini ümumi bir ideya birləşdirir. Bir
              kolleksiyanın içində bir neçə müxtəlif kapsul ola bilər. MERCH.
              Brendin simvolu ilə istehsal olunan geyim, aksesuar və istənilən
              digər əşyalar. Bu bəzən logotipi olmaya bilər, bunun yerinə həmin
              markanın aparıcı rəngi, şirifi və illustrasiyası ola bilər. BRAND
              DNA. Markanı bir şəxsiyyət kimi formalaşdıran dividual
              xüsusiyyətləri və xarakterik təsirləridir. (brand personality)
              Brendi digərlərindən fərqləndirən və bazarda üstün edən onun
              DNA-sıdır.
            </p>
            <blockquote className="text-md italic leading-8 my-5 p-5 text-black font-semibold">
              <h2>- Premium brendin 4 yaranma qaydası.</h2>
              <h6>
                1.Emosiyaların satışı.Premium məhsullarda keyfiyyətə yüksək
                tələb var,amma həmin məhsulun dəyəri qiymətindən daha yüksək
                olmalıdır.Bu deməkdir ki "qablaşdırılma" üst plana çıxır.Premium
                seqmentin məhsulları ilkin təlabat məhsulu deyil.Əsas brending
                həkayə və imicə önəm verir.
              </h6>
              <h6>
                2.Marketingə yatırım edin. Premium brendin məqsədi niyə onun
                məhsul qiymətinin onunla analoq olan brendlərdən baha olduğunu
                aydınlaşdırmaqdır.Bu marketinglə mümkündür.
              </h6>
              <h6>
                3.Qiymət əsas deyil.Premium brendlərin alıcıları qiymətə həssas
                deyillər.Onların alış-verişləri daha yaxşısına layiq
                olduqlarını,imkanları ilə öz tələblərinin üst-üstə düşdüyünü
                görməkdir.Ona görə də belə alıcılar çox vaxt endirim istəməzlər
                və endirimdən asılı olaraq məhsul almazlar.
              </h6>
              <h6>
                4.Detallara diqqət. Premium brendin istehlakçıları özlərinin
                unikal olduqlarını və o geyimdə rahatlığı hiss
                etməlidir.Məhsulunuzu müştərinizin xasiyyətinə və zövqünə uyğun
                hazırlasanız onun ürəyini fəth edəcəksiniz.Populyar nümunə :baza
                tünd rəngdə palto üçün parlaq rəngdə bir astar.Və ya başqa bir
                stil detal fikirləşin.
              </h6>
            </blockquote>
            <p className="text-base leading-8 my-5">
              - Müasir bazar həddində artıq çirklənib. "Doymuş" məhsullar, aşırı
              istehsal, qlobal böhran. "Dəbdə" olan məhsul qıtlığı yoxdur. Hal
              hazırda açılmayan niş çox az olsa da, markanın unikallığı,
              parçanın təbiiliyi, tikişlərin ideallığı və s... ilə seçilmək
              mümkündür. Dəyərləri formalaşdırmaq. Bir marka istehlakçı ilə necə
              ünsiyyət qurur? Dostluq edir yoxsa məsafə saxlayır? " Hasarlanır"
              yoxsa ictimailəşir? Hədəf auditoriyanızın bu duyğuları oxuması,
              qəbul etməsi və marka ilə əlaqələndirməsi vacibdir. Markalaşmanın
              spesifik istiqaməti olmadan inkişaf etdirə biləcəyinizi
              düşünürsüzsə, xeyr. 20 il əvvəldən Zara, H&M in necə işlədiyini,
              oxşarlığını, əşyalara etiket qoyub satdığını görə bilərik. Artıq
              bunlar inandırıcı deyil. Artıq əsaslı, sübutlu işlərə ehtiyacımız
              var. Yalnız bəyan etmək, fikirləri ifadə etmək deyil həm də
              hərəkətlə göstərmək vacibdir.
            </p>
            <p>
              - Mədəniyyət. Dəyərləriniz, baxışlarınız və fəlsəfəniz. Bu marka
              üzərində böyük təsirə malikdir. Məsələn, bazara mürəkkəb bir
              klassik məhsul gətirməyə qərar verdiz. O məhsulun müəyyən bir
              dünyagörüşü və mədəniyyəti olan insan tərəfindən yaradıla
              biləcəyini anlamaq çətin deyil. Məhsulun arxasında düzgün fərdlər
              olmalıdır. Çünki MARKA təsisçinin zehnindəki düşüncələr, hisslər
              toplusudur. Markaya toxuna bilmirik, yalnız markanın məhsuluna
              toxuna və ala bilirik. Məsələn. Calvin Klein, ağ qara dəst,
              minimalist ofis, şirkətin geyim kodu kimi imic yaradacaq detallara
              marka sahibi özü riayət etdi və bunlar markada da özünü göstərdi.
              Və ya Max Mara qızları. Dəbli, özünü təminatlı, özgüvənli, işgüzar
              və yəqin ki, 35+ Giorgio Armani lüks, qüsursuz və uğurludur. Eyni
              zamanda özü də İtalyan əsilli uğurlu dizaynerdi. Markanın
              geyimində hər kəs özünü müvəffəqiyyətli hiss edir. Ya da United
              Colors of Benetton, o hər kəslə dostdur, yaşından, irqindən,
              cinsindən asılı olmayaraq insanları birləşdirir. Markanın
              geyimləri özünəməxsus hiss etdirir, boz, gündəlik həyat ritmindən
              kənara çıx.
            </p>
            <p className="my-6">
              - Dizayında məlumat yoxdur. Modelin eskizi və ya sadəcə fotoları
              var. Geyimdə bütün detalların görünməməsi mümkündü. Məsələn,
              sadəcə öndən görünüş, geyimin arxa görünüşü necə olmalıdır?
              Tikilməsi mümkündürmü? Reallığa nə qədər uyğundu? Bu vəziyyətdə
              dizaynerin düşüncəsini məhsul kimi siz təsvir etməlisiz. Geyimə
              aid bütün xüsusiyyətlər yazılmalı və dizayner ilə razılaşmalısız.
              Yalnız bütün mərhələni düzgün təsvir etdiyinizə əmin olduqdan
              sonra ideyanı həyata keçirməyə hazırsız. Nəticəyə görə məsuliyyət
              daşıyırsız. Bunları edə bilmək texniki bilgilər gərəkdirir.
            </p>
            <a
              href="#"
              className="text-xs text-indigo-600 font-medium hover:text-gray-900 transition duration-500 ease-in-out"
            >
              #AzerbaijanFashionLab
            </a>
            ,{" "}
            <a
              href="#"
              className="text-xs text-indigo-600 font-medium hover:text-gray-900 transition duration-500 ease-in-out"
            >
              #moda
            </a>
            ,{" "}
            <a
              href="#"
              className="text-xs text-indigo-600 font-medium hover:text-gray-900 transition duration-500 ease-in-out"
            >
              #fashion2024
            </a>
            ,{" "}
            <a
              href="#"
              className="text-xs text-indigo-600 font-medium hover:text-gray-900 transition duration-500 ease-in-out"
            >
              #fashionlab
            </a>
            ,{" "}
            <a
              href="#"
              className="text-xs text-indigo-600 font-medium hover:text-gray-900 transition duration-500 ease-in-out"
            >
              #modanfashion
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
