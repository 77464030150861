import React from "react";
import Team from "../../components/Team";
import Statics from "../../components/Statics";
import Slider from "../../components/Slider";

import { MdOutlineDoubleArrow } from "react-icons/md";
import Collective from "../../components/Collective";
import SlideShow from "../../components/SlideShow";
import { Link } from "react-router-dom";
import CoursePage from "../../components/CoursePage";
const Home = () => {
  return (
    <div>
      <div
        class="relative pt-16 pb-32 flex content-center items-center justify-center mt-16"
        // style={{ minHeight: "95vh" }}
      >
        <div class="absolute top-0 w-full h-full bg-top bg-cover home_page">
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-50 bg-black"
          ></span>
        </div>
        <div class="container relative mx-auto" data-aos="fade-in">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-8/12 px-4 ml-auto mr-auto text-white">
              <div className="text-center">
                <h4 class="text-5xl text-center mb-1 font-semibold mb-4">
                  Bizimlə tanış olmağa hazırsınız?
                </h4>

                <p class="text-xl">
                Kolleksiyaların ideyadan asılqana yol xəritəsi
                </p>
                <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-6 justify-center">
                 <Link to={"/login"}>
                 <button className="mt-4 px-8 py-2 text-white rounded-lg bg-black">
                    Başlayaq
                  </button>
                 </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <SlideShow /> */}

      {/* <Slider /> */}

      <CoursePage />
      <Collective />
      <Statics />
    </div>
  );
};

export default Home;
