import React from "react";
import { Link } from "react-router-dom";
import CoursesPage from "../assets/image/courses/coursesPage.jpeg";
import book from "../assets/image/book/book.jpeg"
const CoursePage = () => {
  return (
    <div class="p-20">
      <h3 class="text-4xl md:text-6xl text-gray-700 font-semibol text-center mb-16">
        Kurslarımız
      </h3>
      <div class="bg-white rounded-lg shadow-2xl md:flex">
        <img
          src={CoursesPage}
          alt="Laptop on Desk"
          className="lg:h-72 md:h-48 w-full object-cover object-center"
        />
        <div class="p-6">
          <h2 class="font-bold text-xl md:text-3xl mb-2">Kursumuz haqqında</h2>
          <p class="">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia odio
            maxime illo repellendus asperiores deserunt fugit harum suscipit
            aperiam, possimus quidem, blanditiis saepe beatae molestiae aliquam?
          </p>
          <Link to={"/courses"}>
            <button className="px-12 py-1 rounded-lg bg-black text-white font-normal text-lg mt-16">
              Daha çox
            </button>
          </Link>
        </div>
      </div>
      <section>
        <h3 class="text-4xl md:text-6xl text-gray-700 font-semibol text-center mb-16 mt-20">
          Kitablarımız
        </h3>

        <div class="box-border flex flex-col items-center content-center px-8 mx-auto mt-2 leading-6 text-black border-0 border-gray-300 border-solid md:mt-20 xl:mt-12 md:flex-row max-w-8xl lg:px-16">
          <div class="box-border relative w-full max-w-md px-4 mt-10 mb-4 text-center bg-no-repeat bg-contain border-solid md:mt-0 md:max-w-none lg:mb-0 md:w-1/2">
            <img src={book} class="pl-4 sm:pr-10" />
          </div>
          <div class="box-border w-full text-black border-solid md:w-1/2 mt-16">
            <h2 class="m-0 text-xl font-semibold leading-tight border-0 border-gray-300 lg:text-3xl md:text-2xl">
             Kitabımız
            </h2>
            <p class="pt-4 pb-8 m-0 leading-7 text-black border-0 border-gray-300 sm:pr-10 lg:text-lg ">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit qui
              suscipit laudantium iste mollitia. Ea repellat delectus nisi
              dignissimos?
            </p>
            <Link to={"/book"}>
            <button className="px-12 py-1 rounded-lg bg-black text-white font-normal text-lg mt-16">
              Daha çox
            </button>
          </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CoursePage;
