import React, { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Navigation from "./common/Header/Navigation";
import Home from "./pages/Home";
import About from "./pages/About";
import Book from "./pages/Book";
import Courses from "./pages/Courses";
import Cabinet from "./pages/Cabinet";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
import Footer from "./common/Footer/Footer";
import Login from "./pages/Auth/Login";
import CourseDetails from "./components/CourseDetails";
import ProtectedRoute from "./components/ProtectedRoute";
import Register from "./pages/Auth/Register";
import Blog from "./pages/Blog";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Authentication state
  const location = useLocation();

  // Hide layout (Navigation & Footer) for login and cabinet routes
  const hideLayout = ["/login", "/cabinet", "/register"].includes(
    location.pathname
  );

  return (
    <div className="montserrat-family">
      {!hideLayout && <Navigation />}
      <Routes>
        <Route
          path="/login"
          element={<Login setIsAuthenticated={setIsAuthenticated} />}
        />
        <Route path="/register" element={<Register />} />
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/book" element={<Book />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/courses/:id" element={<CourseDetails />} />
        <Route
          path="/cabinet"
          element={
            <ProtectedRoute
              element={Cabinet}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {!hideLayout && <Footer />}
    </div>
  );
}

export default App;
